<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="isSharingScreen ? 'primary': 'white'"
        :class="isSharingScreen ? 'white--text' : 'primary--text'"
        fab dark v-bind="attrs" :disabled="disabled" v-on="on" @click="$emit('click')"
      >
        <v-icon v-text="isSharingScreen ? 'screen_share' : 'stop_screen_share'" />
      </v-btn>
    </template>
    <span v-text="isSharingScreen ? $t('streaming.buttons.stopSharingScreen') : $t('streaming.buttons.startSharingScreen')" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'SwitchShareScreenButton',
  props: {
    isSharingScreen: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false },
  },
}
</script>
